<template>
    <p
        :style="color != undefined ? `color: ${color}` : null"
        :class="{
            'center': center,
            'primary': primary,
            'black': black,
            'white': white,
            'noMargin': noMargin,
            'right': right
        }"
    >
        <slot />
    </p>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            default: '#ffffff'
        },

        center: {
            type: Boolean,
            default: false,
        },

        primary: {
            type: Boolean,
            default: false,
        },

        black: {
            type: Boolean,
            default: false,
        },

        white: {
            type: Boolean,
            default: false,
        },

        noMargin: {
            type: Boolean,
            default: false,
        },

        right: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style lang="scss" scoped>
p {
    margin: rem(20) 0
}

.noMargin {
    margin: 0;
}

.center {
    text-align: center;
}

.primary {
    color: $primary !important;

    a {
        color: $primary !important;

        &:hover {
            text-decoration: none;
        }
    }
}

.white {
    color: $white !important;

    a {
        color: $white !important;
    
    }
}

.black {
    color: $black !important;
}

.right {
    text-align: right;
}
</style>