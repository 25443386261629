var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{class:{
        'center': _vm.center,
        'primary': _vm.primary,
        'black': _vm.black,
        'white': _vm.white,
        'noMargin': _vm.noMargin,
        'right': _vm.right
    },style:(_vm.color != undefined ? ("color: " + _vm.color) : null)},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }